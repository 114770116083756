<template>
  <div class="tw-w-full">
    <loader :color="companyData.companyColor" :loading="loading" v-if="loading" />
  <v-col sm="12" class="main-body " v-else>
  <v-col sm="12" lg="8" class="tw-p-0 tw-flex-col">
    <div class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row lg:tw-justify-between">
      <icon-and-text :icon="terminalPlus" title="New Terminal" desc="Add a Terminal" :width="$vuetify.breakpoint.mdAndUp ? '30%' : '100%'" @clickAction="newTerminalTab" />
      <icon-and-text :icon="terminalCash" title="View Terminal" desc="All Terminals" :width="$vuetify.breakpoint.mdAndUp ? '30%' : '100%'" @clickAction="selectTab" />
      <image-and-text :img="locationImg" :title="totalTerminals.toString()" desc="Terminals" :width="$vuetify.breakpoint.mdAndUp ? '30%' : '100%'" />
    </div>

    <div class="tw-flex tw-w-full tw-flex-row tw-justify-between tw-py-5 tw-mt-8 table-container-div tw-px-1" style="background: white;">
      <div class="table-container tw-pl-5">
        <p :style="{color:companyData.companyColor}" class="table-container-header mb-0 tw-px-4">Recent Terminals</p>
        <v-data-table
            :headers="headers"
            v-model="selectedRow"
            :mobile-breakpoint="0"
            :search="search"
            item-key="id"
            :single-select="singleSelect"
            height="available"
            :hide-default-footer="recentTerminals.length <= 10"
            :items="recentTerminals"
            :no-data-text="not_found"
            class="mt-4 mx-1 table"
            :checkbox-color="companyData.companyColor"
            @click:row="viewSingle"
        >
          <template #header.terminalImage>
            <span class="status">Image</span>
          </template>
          <template v-slot:[`item.terminalImage`]="{ item }">
            <img
                v-if="item.terminalImage"
                :src="item.terminalImage"
                alt="terminal"
            />

            <div
                class="tw-flex tw-justify-center tw-items-center no-image"
                v-else
                :style="{ backgroundColor: companyData.companyColor }"
            >
              <avatar :avatar="item.terminalName[0]" :color="companyData.companyColor" />

            </div>
          </template>
          <template #header.terminalId>
            <span class="status">Terminal ID</span>
          </template>
          <template v-slot:[`item.terminalId`]="{ item }">
            <span class="td-style">{{ item.terminalId }}</span>
          </template>
          <template #header.terminalName>
            <span class="status">Terminal Name</span>
          </template>
          <template v-slot:[`item.terminalName`]="{ item }">
            <span class="td-style">{{ item.terminalName }}</span>
          </template>
          <template #header.houseNumber>
            <span class="status">House Number</span>
          </template>
          <template v-slot:[`item.houseNumber`]="{ item }">
            <span class="td-style">{{ item.address.houseNumber }}</span>
          </template>
          <template #header.streetName>
            <span class="status">Street Name</span>
          </template>
          <template v-slot:[`item.streetName`]="{ item }">
            <span class="td-style">{{ item.address.streetName }}</span>
          </template>
          <template #header.townCity>
            <span class="status">Town/City</span>
          </template>
          <template v-slot:[`item.townCity`]="{ item }">
            <span class="td-style">{{ item.address.city }}</span>
          </template>
          <template #header.state>
            <span class="status">State</span>
          </template>
          <template v-slot:[`item.state`]="{ item }">
            <span class="td-style">{{ item.address.state }}</span>
          </template>
          <template #header.country>
            <span class="status">Country</span>
          </template>
          <template v-slot:[`item.country`]="{ item }">
            <span class="td-style">{{ item.address.country }}</span>
          </template>

        </v-data-table>
      </div>
    </div>

    <div class="tw-flex tw-w-full tw-justify-between tw-py-5 tw-my-8 tw-px-1">
      <spline-chart :company-color="companyData.companyColor" chart-type="area" title="Popular Terminals"
                    width="100%" :showSelect="false" :usageCharts="true" :bookingCharts="false"/>
    </div>

  </v-col>
  <v-col sm="12" lg="4" class="tw-p-0 tw-flex-col tw-justify-center tw-items-center lg:tw-px-3">
    <v-col sm="12" class="px-1 tw-bg-white" style="border-radius: 10px;">
      <v-col sm="12">
       <v-text-field :color="companyData.companyColor"
            placeholder="Search"
            class="p-0"
            rounded
            dense>
         <template #append>
           <v-icon :color="companyData.companyColor">
             mdi-filter-variant
           </v-icon>
         </template>
       </v-text-field>
      </v-col>
      <v-col sm="12">
          <v-date-picker no-title flat elevation="0" class="date"
                        style="border: none!important; background: #FDFFFC!important;
                          margin-top: 1rem;" full-width :color="companyData.companyColor"
                        v-model="selectedDate" :header-color="companyData.companyColor"
                         :event-color="companyData.companyColor" :max="minDate" />
          <div class=" tw-flex tw-px-8 tw-flex-col">
            <v-divider />

          </div>

      </v-col>

      <v-col sm="12" class="tw-p-10 ">
        <v-progress-linear
            :color="companyData.companyColor"
            indeterminate
            rounded
            height="2"
            v-if="terminalLoading"
        ></v-progress-linear>
        <div class="tw-w-full" v-else>
          <p :style="{color:companyData.companyColor}" class="right-side-header">Terminals</p>
          <div class="tw-flex tw-w-full tw-flex-col scroll tw-items-start tw-justify-center" v-if="terminalBookings.length">
            <div v-for="(terminal,index) in terminalBookings" :key="index" class="tw-flex tw-flex-row tw-w-full terminal-card tw-mt-5">
              <img :src="terminal.terminalImage" alt="terminal image" class="tw-w-4/12 terminal-card-img">
              <div class="tw-flex tw-w-8/12 tw-justify-center tw-flex-col tw-pl-5">
                <h6 class="terminal-card-header">{{terminal.terminalName}}</h6>
                <label class="terminal-card-desc tw-mt-3">{{terminal.totalBookings > 10 ? 'Over '+terminal.totalBookings : terminal.totalBookings}} Bookings</label>
              </div>
            </div>
          </div>
          <p v-else :style="{color:companyData.companyColor}" class="right-side-header scroll">No Bookings Yet</p>
        </div>
      </v-col>
    </v-col>
  </v-col>
</v-col>
  </div>
</template>

<script>
import dayjs from "dayjs"
import IconAndText from '../IconAndText.vue'
import ImageAndText from '../ImageAndText.vue'
import terminalCash from '@/assets/icons/terminal-cash.svg'
import terminalPlus from '@/assets/icons/terminal-plus.svg'
import terminalPack from '@/assets/terminal-pack.png'
import locationImg from '@/assets/location.png'
import SplineChart from "../BookingChart";
import {getTerminalBookingsByDate,getTransportCompanyTerminals} from "@/services/api/APIService";
import Avatar from "../../reuseables/Avatar";
import Loader from "../../reuseables/Loader";


export default {
  name: "ViewTerminalDashboard",
  components: {Loader, Avatar, IconAndText, ImageAndText, SplineChart },
  data() {
    return {
      search: '',
      terminalCash,
      terminalPlus,
      locationImg,
      terminalPack,
      selectedDate : "",
      minDate: (new Date(new Date().getTime() + (60 * 60 * 1000))).toISOString().substr(0, 10),
      names: [],
      companyData:{},
      terminalBookings:[],
      terminalLoading: false,
      loading:false,
      recentTerminals:[],
      totalTerminals: "",
      headers: [
        { text: "", value: "terminalImage" },
        { text: "", value: "terminalName" },
        { text: "", value: "terminalId" },
        { text: "", value: "houseNumber" },
        { text: "", value: "streetName" },
        { text: "", value: "townCity" },
        { text: "", value: "state" },
        { text: "", value: "country" },
      ],
      not_found: "Terminals will display here",
      selectedRow: [],
      singleSelect: false,
    }
  },
  watch:{
    selectedDate:{
      handler: async function (val){
        let companyData = JSON.parse(sessionStorage.getItem("companyData"))
        let data = {}
        let today = new Date()
        if (!val){
          data.date =dayjs(today).format("YYYY-MM-DD")
        }
        else {
          data.date = val
        }
        data.transportCompanyId = companyData.id
        if (data.transportCompanyId){

          await this.getTerminalBookingsByDate(data)
        }

      },
      immediate: true
    }
  },
  computed:{

  },
  methods:{
    viewSingle(terminal) {
      this.$store.dispatch(
          "terminalComponent/setCurrentComponent",
          "singleTerminal"
      );
      sessionStorage.setItem("terminalComponent", "singleTerminal");
      sessionStorage.setItem("slugId", terminal.id);
    },
    selectEditTab() {
      this.$store.dispatch('terminalComponent/setCurrentComponent', 'editTerminal')
      sessionStorage.setItem("terminalComponent", 'editTerminal')
    },
    selectTab() {
      this.$store.dispatch('terminalComponent/setCurrentComponent', 'allTerminals')
      sessionStorage.setItem("terminalComponent", 'allTerminals')
    },
    newTerminalTab(){
      this.$store.dispatch('terminalComponent/setCurrentComponent', 'addTerminal')
      sessionStorage.setItem("terminalComponent", 'addTerminal')

    },
    formatDate(date){
      return dayjs(date).format('LL')
    },
   async getTerminalBookingsByDate(data) {
      this.terminalLoading = true
      this.terminalBookings = []
     await getTerminalBookingsByDate(data).then(res => {
       for(const [key,value] of Object.entries(res.data)){
         console.log(key)
         this.terminalBookings.push(value)
       }
       this.terminalLoading = false
      }).catch(error => {
        console.log(error);
       this.terminalLoading = false
        this.$displaySnackbar({
          message: error.response.data.details[0],
          success: false,
        });
      })
    },
   async getAllTerminals(){
      this.loading =true
      await  getTransportCompanyTerminals(this.companyData.id).then(res => {
        this.totalTerminals= res.data.length
        if (res.data.length > 2){
          this.recentTerminals = res.data.slice(-2)
        }else {
          this.recentTerminals = res.data
        }
        this.loading = false
      }).catch(error => {
        console.log(error);
        this.loading = false
        this.$displaySnackbar({
          message: error.response.data.details[0],
          success: false,
        });
      })
    }
  },
  mounted () {

  },
 async created() {
    this.loading = true
    let companyData = JSON.parse(sessionStorage.getItem("companyData"))
    if (companyData){
      this.companyData = companyData
    }
    await  this.getAllTerminals()
  }
}
</script>

<style lang="scss" scoped>
.my-event {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  background-color: #1867c0;
  color: #ffffff;
  border: 1px solid #1867c0;
  font-size: 12px;
  padding: 3px;
  cursor: pointer;
  margin-bottom: 1px;
  left: 4px;
  margin-right: 8px;
  position: relative;
}

.my-event.with-time {
  position: absolute;
  right: 4px;
  margin-right: 0px;
}
.v-text-field--rounded > .v-input__control > .v-input__slot {
    padding: 0px 0px !important;
}

.input-border {
  border: 1px solid rgba(79, 79, 79, 0.08);
  border-radius: 10px;
  padding: 0 !important;
  // padding-top: 6px;
  // height: 38px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
}

.table-container-div {
  box-shadow: 0px 4px 40px 0px #4f4f4f05;
  border-radius: 10px;
  max-height: 28vh;
  height: 28vh;
  .no-image {
    width: 32px;
    height: 32px;
    border-radius: 50%;

    p {
      font-family: "Inter", serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0;
      margin-bottom: 0 !important;
      color: rgba(255, 255, 255, 1);
    }
  }

  img {
    width: 32px;
    height: 32px;
    border-radius: 5px;
  }
}
.table-container {
  width: 100%;
  padding-right: 30px;
  background: #fdfffc;
  max-height: 25vh;
  .table-container-header {
    font-family: "Inter", serif !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0;
    text-align: left;
    color: #004aad;
  }
}
.table-container::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}
.table-container::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 20px !important;
  border-radius: 6px;
}

::v-deep .v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0;
}

.v-data-table__wrapper > table {
  margin-top: 55px !important;
}

.theme--light.v-data-table
> .v-data-table__wrapper
> table
> thead
> tr
> th:first-child,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child {
  padding-left: 16px;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.status {
  font-family: "Poppins",sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #4f4f4f;
  padding: 0 0 0 5px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.td-style {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0;
  text-align: left;
  padding: 0 0 0 5px;
}

.main-body{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: available;
  padding: 0!important;

  @media screen and (max-width: 820px) {
    padding: 1.5rem!important;
    flex-direction: column;
  }
}

.right-side-header {
  font-family: 'Inter',serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
  text-align: left;
}

.scroll {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 23vh;
  height: 23vh;
}
.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}
.scroll::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 20px !important;
  border-radius: 6px;
}

.date{
  font-family: Inter,serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px !important;
  line-height: 22px;
  color: #004AAC;
}
.pending-trips{
    font-family: Inter,serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 16px !important;
    line-height: 19px;
    color: #004AAC;
}
.current-date{
  font-family: Inter,serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #4F4F4F;
}

.terminal-card{
  background: #FDFFFC;
  box-shadow: 0 2px 20px rgba(79, 79, 79, 0.04);
  border-radius: 10px;
  height: 85px;
}

.terminal-card-header{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #4F4F4F;
}

.terminal-card-desc{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 11px;
  color: #4F4F4F;
}

.terminal-card-img{
  border-radius: 10px 0 0 10px;
}

.v-data-table--fixed-height .v-data-table__wrapper {

}

::v-deep .v-data-table--fixed-height .v-data-table__wrapper::-webkit-scrollbar {
  background: transparent !important;
  width: auto;
  height: 2px;
}
::v-deep .v-data-table--fixed-height .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 1px !important;
  border-radius: 10px;
}
</style>